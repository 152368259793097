import * as React from "react"
import Layout from '../components/layout'

const TestPage = (props) => (
    <Layout title="Test">
      <h1>Test</h1>
    </Layout>
)

export default TestPage

//export const Head = () => <title>Home Page</title>
